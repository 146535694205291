@import "../src/common/scss/variables";
@import "~tailwindcss/base";
@import "~tailwindcss/utilities";
@import "~tailwindcss/components";
@import "~@materializecss/materialize/sass/components/color-variables";
@import "~@materializecss/materialize/sass/components/variables";
@import "~@materializecss/materialize/sass/components/sidenav";
@import "~@materializecss/materialize/sass/components/tooltip";
@import "~@materializecss/materialize/sass/components/collapsible";
@import "~@materializecss/materialize/sass/components/buttons";
@import "~@materializecss/materialize/sass/components/dropdown";
@import "~@materializecss/materialize/sass/components/datepicker";
@import "~@materializecss/materialize/sass/components/timepicker";
@import "~@materializecss/materialize/sass/components/slider";
@import "~@materializecss/materialize/sass/components/waves";
@import "~@materializecss/materialize/sass/components/pulse";
@import "~@materializecss/materialize/sass/components/modal";
@import "~@materializecss/materialize/sass/components/navbar";
@import "~@materializecss/materialize/sass/components/grid";
@import '../src/common/scss/common';
@import '../src/common/scss/buttons';
@import '../src/common/scss/cards';
@import '../src/common/scss/nav';
@import '../src/logbook/scss/logbook';
@import '../src/datatables/scss/datatable';
@import '../src/common/scss/toastr';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html{
    font-size: 35px;
  }
  @media (min-width: 640px) {
    html{
      font-size: 30px;
    }
  }
  @media (min-width: 768px){
    html{
      font-size: 20px;
    }
  }
  @media (min-width: 1000px){
    html{
      font-size: 17px;
    }
  }
  @media (min-width: 1280px) {
    html{
      font-size: 14px;
    }
  }
}

@layer components {
  .nav-active{
    &:hover{
      svg {
        @apply text-teal-500
      }
    }
    svg{
      @apply text-teal-400
    }
  }

  .nav-inactive{
    &:hover{
      svg {
        @apply text-gray-500
      }
    }
    svg{
      @apply text-gray-400
    }
  }

  #error_explanation{
    @apply text-base font-semibold text-red-500
  }

  .dropdown-content li > a, .dropdown-content li > span {
    @apply text-base
  }
  .nav-li{
    @apply w-60 bg-transparent hover:bg-blue-700 pl-6 my-3
  }
  .collapsible{
    li{
      &.active{
        .collapsible-icon{
          @apply rotate-180
        }
      }
      .collapsible-icon{
        @apply text-3xl duration-500 float-right mx-2 origin-center;
      }
    }
  }
}
@layer utilities {
  .font-title{
    font-family: 'Racing Sans One', cursive;
  }
}
