@import "variables";

.toast-top-center{
  margin-top: 3vh;
}
body{

}
#toast-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(50%);
  top: 0%;
  right: 50%;
  > div{
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
  }
  .toast{
    &.error{
      @extend .theme-warn-bg;
      @extend .valign-wrapper !optional;
    }
    &.success{
      @extend .theme-primary-bg;
      @extend .valign-wrapper !optional;
    }
  }
  .toast-close-button{
    display: none;
  }
}