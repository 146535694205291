/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 */


html, body
{
  p{
    overflow-wrap: anywhere;
  }
  .hide{
    display: none;
  }
  .row{
    margin-bottom: 0;
  }
  nav ul a {
    font-size: 1.3rem;
  }
  .full-width{
    width: 100%;
  }
  nav i, nav [class^="mdi-"], nav [class*="mdi-"], nav i.material-icons{
    font-size: 1.6rem;
  }
  .sidenav{
    width: 15rem;
    .navbar-brand{
      font-size: 2rem;
    }
    a {
      //@extend .white-text;
    }
    li, li > a, .collapsible-header{
      line-height: unset;
      height: auto;
    }
    li > a, .collapsible-header{
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .collapsible-header{
      padding-left: 32px;
      padding-right: 32px;
    }
    .collapsible-body{
      @extend .theme-primary-bg;
      a {
        //@extend .white-text;
        padding: 0.5rem 32px 0.5rem 64px;
        line-height: unset;
        height: auto;
      }
    }
  }
  .btn, .btn-floating, .btn-large, .btn-small, .btn-flat, .tabs .tab a, .sidenav li > a{
    font-size: 0.93rem;
  }

  .btn, .btn-small, .btn-large, .btn-flat{
    height: 2.4rem;
    line-height: 2.4rem;
  }
  .col {
    &.col-padding{
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }
}


.translation_missing{
  color: rebeccapurple;
  border: 1px rebeccapurple;
}

.center-align-flex{
  display: flex;
  justify-content: center;
  &.vertical{
    align-content: center;
    align-items: center;
  }
}

.ghost-text{
  color: #b9b9b9;
}

.bold{
  font-weight: bold;
}
