// Place all the styles related to the logbook controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/


.logbook{

  tr{
    border-bottom: 1px solid lightgrey;
    th, td{
      border-right: 1px solid lightgrey;
      text-align: center;
    }
    th:last-child, td:last-child{
      border-right: none;
    }
  }
  .logged_row_bg{
    background-color: #c5ffdd;
    &:hover{
      background-color: darken(#C5FFDD, 5%);
    }
  }
}