@import "variables";

.button{
  @apply py-3 py-0 text-white shadow-lg px-5 rounded-md text-lg my-3 desktop:my-5 min-h-[2.5rem] align-middle flex items-center cursor-pointer
  transition-colors duration-200 ease-out text-center mx-5;
}
.btn-primary{
  @extend .theme-primary-bg;
  &:focus{
    @extend .theme-primary-bg-darker;
  }
  &:hover{
    @extend .theme-primary-bg-lighter;
  }
}

.fixed-action-btn {
  ul {
    bottom: 5.33rem;
  }
}

.dropdown-content{
  .button_to{
    input[type='submit']{
      @extend .theme-accent;
      display: block;
      line-height: 22px;
      padding: 14px 16px;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
}
