.dataTable-sorting{
  float: right;
  color: #ddd;
  font-size: 1rem;
  height: 100%;
}

table{
  &.center-align-text{
    th, td{
      text-align: center;

    }
    td {

    }
    //@media #{$medium-and-down} {
    //  thead{
    //    tr{
    //      border-bottom: none;
    //    }
    //  }
    //  tbody{
    //    tr{
    //      border: 1px $gray solid;
    //    }
    //    td{
    //      border-top: 1px $gray-light solid ;
    //      border-left: none;
    //      border-right: none;
    //      border-bottom: none;
    //    }
    //  }
    //}
    tr{

    }
  }
}

.datatable-head {
  display: flex;
  align-items: center;
  .input-field{
    width: 100%;
  }
}

//.datatable-head:after {
//  content: "";
//}

table.dataTable thead th:focus{
  outline: none;
}
//table.dataTable thead th.sorting_asc div.datatable-head:after {
//  content: "";
//}
//table.da`taTable thead th.sorting_desc div.datatable-head:after {
//  content: "";
//}


.datatable-head > .sorting-icons {
  .sorting-icons-asc, .sorting-icons-desc {
    display: none;
  }
  cursor: pointer;
  @extend .dataTable-sorting;
}

table.dataTable thead th.sorting_asc div.datatable-head > .sorting-icons {
  .sorting-icons-sort, .sorting-icons-desc {
    display: none;
  }
  .sorting-icons-asc {
    display: inline-block;
  }
  @extend .dataTable-sorting;
  color: grey;

}
table.dataTable thead th.sorting_desc div.datatable-head > .sorting-icons {
  .sorting-icons-asc, .sorting-icons-sort {
    display: none;
  }
  .sorting-icons-desc {
    display: inline-block;
  }
  @extend .dataTable-sorting;
  color: grey;
}

.datatable-search-inline{
  &:focus{
    outline: none;
  }
  &.input-field.inline{
    margin-top: 0;
    margin-bottom: 0;
    input {
      margin: 0;
    }
  }
  input[type=text]:focus{
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 0;
    margin: 0;
  }
}




.no-search {
  margin: 10px;
  justify-content: center;
  display: flex;
}

.dataTables_processing {

}

div.dataTables_info {

}

.datatable {
  table{
    .col{
      float: none;
    }
  }
  .item_count{
    padding: 8px 0;
    white-space: nowrap;
  }
  .per_page{
    .input-field{
      min-width: 50px;
      width: 25%;
      .select-dropdown{
        margin-bottom: 0;
      }
    }
  }
  .paging{
    text-align: right;
    span{
      height: 100%;
    }
    .paginate_button{
      font-size: 1.5rem;
      display: inline-block;
      border-radius: 2px;
      text-align: center;
      min-width: 1.3rem;
      border: none;
      background-color: transparent;
      appearance: none;
      padding: 0;
      line-height: 1.5;
      //@extend .waves-effect;
      @extend .theme-primary;
      &.current{
        @extend .theme-primary-bg;
        cursor: default;
      }
      .valign-wrapper{
        height: 100%
      }
      .material-icons{

        font-size: 20px;
      }
      &:focus{
        outline: none;
      }
      &.disabled{
        cursor: default;
        @extend .theme-grey-darker;
      }
    }
  }
  .processing{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    z-index: 100;
    .spinner-container{
      margin: 0 auto;
      width: 10vw;
    }
  }
  .sorting-icons{
    .sorting-icon{
      @extend .theme-primary;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
    justify-content: center;
  }
  .link-row{
    cursor: pointer;
  }
}