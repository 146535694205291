$button-floating-large-size: 4.67rem;
$button-floating-size: 3.33rem;
$primary-color: #2196f3;
$primary-color-light: #6ec6ff;
$primary-color-dark: #0069c0;

$secondary-color: #00bcd4;
$secondary-color-light: #62efff;
$secondary-color-dark: #008ba3;
$success-color: #4caf50;
$error-color: #f44336;
$link-color: #03a9f4;


$warn-color: #ff5722;
$warn-color-light: #fbe9e7;
$warn-color-dark: #e64a19;



$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #eee

html, body.mat-app-background{
  .theme-primary-bg{
    background-color: $primary-color;
    color: white;
  }

  .theme-primary-bg-lighter{
    background-color: $primary-color-light;
    color: white;
  }

  .theme-primary-bg-darker{
    background-color: $primary-color-dark;
    color: white;
  }

  .theme-primary-border{
    border: 1px solid $primary-color;
  }

  .theme-accent-bg{
    background-color: $secondary-color;
    &:hover{
      background-color: darken($secondary-color, 5%);
    }
  }

  .theme-accent-bg-lighter{
    background-color: $secondary-color-light;
    color: black;
    &:hover{
      background-color: darken($secondary-color-light, 5%);
    }
  }

  .theme-accent-bg-darker{
    background-color: $secondary-color-dark;
    color: white;
  }

  .theme-warn-bg{
    background-color: $warn-color;
    color: white;
  }

  .theme-warn-bg-lighter{
    background-color: $warn-color-light;
    color: black;
  }

  .theme-warn-bg-darker{
    background-color: $warn-color-dark;
    color: white;
  }

  .theme-primary{
    color: $primary-color;
  }

  .theme-primary-lighter{
    color: $primary-color-light;
  }

  .theme-primary-darker{
    color: $primary-color-dark;
  }

  .theme-accent{
    color: $secondary-color;
  }

  .theme-accent-lighter{
    color: $secondary-color-light;
  }

  .theme-accent-darker{
    color: $secondary-color-dark;
  }

  .theme-warn{
    color: $warn-color;
  }

  .theme-warn-lighter{
    color: $warn-color-light;
  }

  .theme-warn-darker{
    color: $warn-color-dark;
  }


  .theme-grey-border {
    border: 1px solid #f5f5f5;
  }

  .theme-grey-bg {
    background-color: #f5f5f5;
  }

  .theme-grey-bg-darker {
    background-color: darken(#f5f5f5, 15%);
  }

  .theme-grey-darker {
    color: darken(#f5f5f5, 15%);
  }
}
.title-font{
  font-family: 'Racing Sans One', cursive ;
  @extend .theme-primary-bg;
}

// Z-levels
.z-depth-0 {
  box-shadow: none !important;
}

/* 2dp elevation modified*/
.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),
  0 3px 1px -2px rgba(0,0,0,0.12),
  0 1px 5px 0 rgba(0,0,0,0.2);
}
.z-depth-1-half {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

/* 6dp elevation modified*/
.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),
  0 1px 10px 0 rgba(0,0,0,0.12),
  0 2px 4px -1px rgba(0,0,0,0.3);
}

/* 12dp elevation modified*/
.z-depth-3 {
  box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14),
  0 3px 14px 2px rgba(0,0,0,0.12),
  0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

/* 16dp elevation */
.z-depth-4 {
  box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14),
  0 6px 30px 5px rgba(0,0,0,0.12),
  0 8px 10px -7px rgba(0,0,0,0.2);
}

/* 24dp elevation */
.z-depth-5 {
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14),
  0 9px 46px 8px rgba(0,0,0,0.12),
  0 11px 15px -7px rgba(0,0,0,0.2);
}


// Tabs
$tabs-underline-color: $primary-color-dark;
$tabs-text-color: $gray-light;
