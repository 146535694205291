.card {
  .card-header{
    padding: 10px 15px;
    h2{
      margin: 0;
    }
    h5{
      margin: 0;
    }
    h6 {
      margin: 0;
    }
  }
  .card-content {
    p {
      margin-bottom: 10px;
    }
    padding: 10px 20px;
    table {
      tbody {
        tr {
          td{
            padding: 12px 5px;
          }
        }
      }
    }
    &.collection{
      padding: 0;
      border: 0;
      margin-top: 0;
      .collection-item{
        text-align: center;
      }
    }
    .collapsible{
      .collapsible-header{
        flex-wrap: wrap;
        .row{
          flex-basis: 100%;
          margin-top: 0;
          margin-bottom: 0;
          &.ghost-text > .col{
            display: flex;
            justify-content: space-evenly;
          }
        }
      }
    }
  }
}