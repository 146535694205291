@import 'variables';

nav{
  .navbar-brand{
    @extend .title-font;
    font-size: 2rem;
    color: white;
  }
}

.sidenav{
  .navbar-brand{
    @extend .title-font;
    color: white;
  }
}
